import {
  CartSettings,
  CookieSettings,
  Discount,
  FooterSettings,
  GeneralSettings,
  PromoSettings,
  SeoSettings,
  SocialLink,
  Strings,
} from '@data/sanity/schema'
import { SanityBlogSettings } from './blog'
import { SanityContentFragment } from './content'
import { SanityImageFragment } from './image'
import { SanityLinkFragment, SanityLinkReference } from './link'
import { SanityHasSlug } from './page'
import { SanityProductFragment } from './product'

export type SanityPromo = Pick<
  PromoSettings,
  'enabled' | 'display' | 'text'
> & {
  link?: SanityLinkReference
}

export type SanityMenuLinkFragment = SanityLinkFragment & {
  dropdownItems: SanityLinkFragment[]
}

export type SanityMenuFeaturedLinkFragment = SanityMenuLinkFragment & {
  featured?: SanityProductFragment[]
}

export type SanityHeaderDesktopMenu = SanityHasSlug & {
  items: SanityMenuFeaturedLinkFragment[]
}

export type SanityHeaderMobileMenu = SanityHasSlug & {
  items: SanityMenuLinkFragment[]
}

export interface SanityHeaderFragment {
  menuDesktopLeft?: SanityHeaderDesktopMenu
  menuDesktopRight?: SanityHeaderDesktopMenu
  menuMobilePrimary?: SanityHeaderMobileMenu
  menuMobileSecondary?: SanityHeaderMobileMenu
  promo?: SanityPromo
}

export interface SanityFooterBlockMenu {
  items: SanityLinkFragment[]
}

export type SanityFooterSocialLink = Pick<
  SocialLink,
  'socialNetwork' | 'url'
> & {
  _key: string
}

export interface SanityFooterFragment {
  content?: SanityContentFragment[]
  menu?: SanityFooterBlockMenu
  social?: SanityFooterSocialLink[]
  copyright?: FooterSettings['copyright']
}

export type SanityCart = Pick<
  CartSettings,
  | 'storeURL'
  | 'taxRate'
  | 'message'
  | 'openInSeparatePage'
  | 'showVatId'
  | 'showComment'
> & {
  terms?: SanityContentFragment[]
}

export type SanityProductCount = SanityHasSlug & {
  count: number
}

export type SanityCookieConsent = Pick<
  CookieSettings,
  'enabled' | 'message'
> & {
  link?: SanityLinkReference
}

export type SanitySeo = Pick<
  SeoSettings,
  | 'siteTitle'
  | 'metaTitle'
  | 'metaDesc'
  | 'shareTitle'
  | 'shareDesc'
  | 'shareGraphic'
>

export type SanityAccountStrings = Pick<
  Strings,
  | 'account'
  | 'accountAccountDetailsHeading'
  | 'accountViewAddresses'
  | 'accountViewSubscriptions'
  | 'accountDefaultAddressLabel'
  | 'accountAddAddressHeading'
  | 'accountEditAddressHeading'
  | 'accountFirstName'
  | 'accountFirstNamePlaceholder'
  | 'accountFirstNameMissing'
  | 'accountLastName'
  | 'accountLastNamePlaceholder'
  | 'accountLastNameMissing'
  | 'accountCompany'
  | 'accountCompanyPlaceholder'
  | 'accountCompanyMissing'
  | 'accountAddressLine1'
  | 'accountAddressLine1Placeholder'
  | 'accountAddressLine1Missing'
  | 'accountAddressLine2'
  | 'accountAddressLine2Placeholder'
  | 'accountCity'
  | 'accountCityPlaceholder'
  | 'accountCityMissing'
  | 'accountCountry'
  | 'accountCountryPlaceholder'
  | 'accountCountryMissing'
  | 'accountZip'
  | 'accountZipPlaceholder'
  | 'accountZipMissing'
  | 'accountPhone'
  | 'accountPhonePlaceholder'
  | 'accountPhoneMissing'
  | 'accountSetAsDefault'
  | 'addressErrorMessage'
  | 'accountAddAddress'
  | 'accountEditAddress'
  | 'accountProductListHeading'
  | 'accountProductListProduct'
  | 'accountProductListViewProduct'
  | 'accountOrderListHeading'
  | 'accountOrderListOrder'
  | 'accountOrderListDate'
  | 'accountOrderListPaymentStatus'
  | 'accountOrderListFulfillmentStatus'
  | 'accountOrderListTotal'
  | 'accountOrderDetailsHeading'
  | 'accountOrderDetailsDate'
  | 'accountOrderDetailsProduct'
  | 'accountOrderDetailsSku'
  | 'accountOrderDetailsPrice'
  | 'accountOrderDetailsQuantity'
  | 'accountOrderDetailsTotal'
  | 'accountOrderDetailsSubtotal'
  | 'accountOrderDetailsShipping'
  | 'accountOrderDetailsTax'
  | 'accountOrderDetailsBillingAddress'
  | 'accountOrderDetailsShippingAddress'
  | 'accountOrderListPaymentStatusAuthorized'
  | 'accountOrderListPaymentStatusPaid'
  | 'accountOrderListPaymentStatusPartiallyPaid'
  | 'accountOrderListPaymentStatusPartiallyRefunded'
  | 'accountOrderListPaymentStatusPending'
  | 'accountOrderListPaymentStatusRefunded'
  | 'accountOrderListPaymentStatusVoided'
  | 'accountOrderListFulfillmentStatusFulfilled'
  | 'accountOrderListFulfillmentStatusInProgress'
  | 'accountOrderListFulfillmentStatusOnHold'
  | 'accountOrderListFulfillmentStatusOpen'
  | 'accountOrderListFulfillmentStatusPartiallyFulfilled'
  | 'accountOrderListFulfillmentStatusPendingFulfillment'
  | 'accountOrderListFulfillmentStatusRestocked'
  | 'accountOrderListFulfillmentStatusScheduled'
  | 'accountOrderListFulfillmentStatusUnfulfilled'
  | 'accountProductListEmpty'
  | 'accountOrderListEmpty'
>

export interface HasAccountStrings {
  accountStrings: SanityAccountStrings
}

export type SanityAuthStrings = Pick<
  Strings,
  | 'signupFirstName'
  | 'signupFirstNamePlaceholder'
  | 'signupFirstNameMissing'
  | 'signupLastName'
  | 'signupLastNamePlaceholder'
  | 'signupLastNameMissing'
  | 'signupEmail'
  | 'signupEmailPlaceholder'
  | 'signupEmailMissing'
  | 'signupPassword'
  | 'signupPasswordPlaceholder'
  | 'signupPasswordMissing'
  | 'signupErrorMessage'
  | 'signupSubmit'
  | 'loginErrorMessage'
  | 'loginFailedMessage'
  | 'loginSubmit'
  | 'passwordRecoveryErrorMessage'
  | 'passwordRecoverySuccessMessage'
  | 'passwordRecoverySubmit'
>

export interface HasAuthStrings {
  authStrings: SanityAuthStrings
}

export type SanityCollectionStrings = Pick<
  Strings,
  | 'collectionProducts'
  | 'collectionProductCount'
  | 'collectionSortLabel'
  | 'collectionSortDescription'
  | 'collectionShowFiltersLabel'
  | 'collectionFilters'
  | 'collectionRemoveFilter'
  | 'collectionClearAllLabel'
  | 'collectionClearFiltersLabel'
  | 'collectionFilterResultsLabel'
  | 'collectionFilterNoResultsLabel'
  | 'collectionFilterModalClose'
  | 'collectionFilterNoResults'
>

export interface HasCollectionStrings {
  collectionStrings: SanityCollectionStrings
}

export type SanityReviewsStrings = Pick<
  Strings,
  | 'reviewsSingular'
  | 'reviewsPlural'
  | 'reviewsRatingBasedOnSingular'
  | 'reviewsRatingBasedOnPlural'
  | 'reviewsNoReviews'
  | 'reviewsCarouselTitle'
  | 'reviewsVerifiedBuyer'
  | 'reviewsWriteReview'
  | 'reviewsFormAuthorInputLabel'
  | 'reviewsFormAuthorInputPlaceholder'
  | 'reviewsFormAuthorMissing'
  | 'reviewsFormEmailInputLabel'
  | 'reviewsFormEmailInputPlaceholder'
  | 'reviewsFormEmailMissing'
  | 'reviewsFormReviewRatingInputLabel'
  | 'reviewsFormReviewRatingMissing'
  | 'reviewsFormReviewTitleInputLabel'
  | 'reviewsFormReviewTitleInputPlaceholder'
  | 'reviewsFormReviewTitleMissing'
  | 'reviewsFormReviewMessageInputLabel'
  | 'reviewsFormReviewMessageInputPlaceholder'
  | 'reviewsFormReviewMessageMissing'
  | 'reviewsFormSubmit'
  | 'reviewsFormErrorMessage'
  | 'reviewsFormSuccessMessage'
>

export interface HasReviewsStrings {
  reviewsStrings: SanityReviewsStrings
}

export type SanitySiteStrings = Pick<
  Strings,
  | 'loadingPageTitle'
  | 'languageSwitch'
  | 'cartLink'
  | 'featuredProducts'
  | 'skipToContent'
  | 'goHomeLabel'
  | 'mobileMenuLabel'
  | 'productLowStock'
  | 'productOutOfStock'
  | 'productPriceLabel'
  | 'productColorOptionLabel'
  | 'productDiscountText'
  | 'productIncreaseQuantity'
  | 'productDecreaseQuantity'
  | 'productEnterQuantity'
  | 'cartTitle'
  | 'cartClose'
  | 'cartEmpty'
  | 'cartVatSectionTitle'
  | 'cartVatIdInputPlaceholder'
  | 'cartVatIdError'
  | 'cartInvalidVatIdError'
  | 'cartCommentSectionTitle'
  | 'cartCommentInputPlaceholder'
  | 'cartDiscount'
  | 'cartSubtotal'
  | 'cartTotal'
  | 'cartSubmit'
  | 'cartAddToCartErrorMessage'
  | 'carouselCounterText'
  | 'carouselLeftArrowLabel'
  | 'carouselRightArrowLabel'
  | 'carouselDotLabel'
  | 'buttonAddToCart'
  | 'buttonRemove'
  | 'buttonAccept'
  | 'buttonLearnMore'
  | 'buttonTryAgain'
  | 'buttonLoadMore'
  | 'buttonUnavailable'
  | 'buttonWaiting'
  | 'buttonLoading'
  | 'buttonAdding'
  | 'buttonUpdating'
  | 'buttonSubmitting'
  | 'buttonEdit'
  | 'buttonDelete'
  | 'buttonCancel'
  | 'emailAddress'
  | 'emailAddressPlaceholder'
  | 'emailMissing'
  | 'emailInvalid'
  | 'firstName'
  | 'firstNamePlaceholder'
  | 'firstNameMissing'
  | 'lastName'
  | 'lastNamePlaceholder'
  | 'lastNameMissing'
  | 'fullName'
  | 'fullNamePlaceholder'
  | 'fullNameMissing'
  | 'phoneNumber'
  | 'phoneNumberPlaceholder'
  | 'phoneNumberMissing'
  | 'phoneNumberInvalid'
  | 'company'
  | 'companyPlaceholder'
  | 'message'
  | 'messagePlaceholder'
  | 'productWaitlistSubmit'
  | 'productWaitlistSuccess'
  | 'productWaitlistError'
  | 'blogPostReadMore'
  | 'blogPostBy'
  | 'teamMemberCardLinkedIn'
  | 'teamMemberCardBlogArticles'
>

export interface SanityIdentity {
  logo?: SanityImageFragment
  invertedLogo?: SanityImageFragment
}

export type PublicSiteSettings = Pick<
  GeneralSettings,
  | 'siteURL'
  | 'hubSpotPortalId'
  | 'klaviyoAccountID'
  | 'gtmContainerId'
  | 'analyticsId'
  | 'facebookEvents'
  | 'facebookPixelId'
  | 'facebookDomainVerification'
  | 'facebookTestEventCode'
  | 'stampedApiKey'
  | 'partnerAdsTracking'
  | 'cookieBotId'
  | 'pageAnimation'
>

export type AllSiteSettings = PublicSiteSettings &
  Pick<
    GeneralSettings,
    | 'hubSpotAccessToken'
    | 'klaviyoPrivateKey'
    | 'stampedStoreHash'
    | 'clickUpApiToken'
  >

export enum SanityDiscountType {
  QUANTITY = 'quantity',
  BUNDLE = 'bundle',
}

export type SanityDiscountProduct = {
  id: string
  variantIds: string[]
}

type SanityPartialDiscount = Pick<
  Discount,
  '_id' | 'title' | 'discountValuePercent'
>

export type SanityQuantityDiscount = SanityPartialDiscount & {
  type: SanityDiscountType.QUANTITY
  minimumQuantity: number
  maximumQuantity: number
}

export type SanityBundleDiscount = SanityPartialDiscount & {
  type: SanityDiscountType.BUNDLE
  products: SanityDiscountProduct[]
  doNotStackWithQuantityDiscounts?: boolean
}

export type SanityDiscount = SanityQuantityDiscount | SanityBundleDiscount

export type SanityDiscountQuery = SanityDiscount[]

export interface SanitySiteFragment {
  publicLocales: GeneralSettings['locale'][]
  settings: PublicSiteSettings
  identity?: SanityIdentity
  cart: SanityCart
  blogSettings: SanityBlogSettings | null
  productCounts: SanityProductCount[]
  cookieConsent?: SanityCookieConsent
  header?: SanityHeaderFragment
  footer?: SanityFooterFragment
  seo?: SanitySeo
  siteStrings?: SanitySiteStrings
  discounts: SanityDiscount[]
}
