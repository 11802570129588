import { ReactNode } from 'react'
import cx from 'classnames'

interface ListProps {
  type: string
  children: ReactNode
  className: string
}

export const CustomList: React.FC<ListProps> = ({ type, children, className }) => {
  switch (type) {
    case 'bullet':
      return <ul className={cx(`list-disc ml-10`, className)}>{children}</ul>
    case 'number':
      return <ol className={cx(`list-decimal ml-10`, className)}>{children}</ol>
    default:
      return <ul className={cx(`list-disc ml-10`, className)}>{children}</ul>
  }
}
