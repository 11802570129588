import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import queryString from 'query-string'
import { v4 as uuidv4 } from 'uuid'

import { Locale } from './language'

export interface ErrorMessages {
  [key: string]: string
}

export interface UrlParameter {
  name: string
  value: string | string[] | null
}

/**
 * Previous value hook.
 */
export function usePrevious<T>(value: T) {
  const previousValue = useRef<T>()

  useEffect(() => {
    previousValue.current = value
  }, [value])

  return previousValue.current
}

/**
 * Client-side mount hook.
 */
export function useHasMounted() {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return hasMounted
}

/**
 * Local storage state variable hook.
 * Syncs state to local storage so that it persists through a page refresh.
 */
export function useLocalStorageState<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }

    try {
      // Read value from local storage
      const item = window.localStorage.getItem(key)

      if (item) {
        return JSON.parse(item)
      }
    } catch (error) {
      console.log(error)
    }

    return initialValue
  })

  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (value) => {
      try {
        const newValue = value instanceof Function ? value(storedValue) : value

        setStoredValue(newValue)

        // Save value to local storage
        window.localStorage.setItem(key, JSON.stringify(newValue))
      } catch (error) {
        console.log(error)
      }
    },
    [key, storedValue]
  )

  return [storedValue, setValue] as const
}

/**
 * Checks if an object is found in another array of objects.
 */
export function hasObject(recs?: any[], vals?: any[] | any) {
  if (!recs) {
    return false
  }

  return recs.some((obj) => {
    for (const x in obj) {
      if (vals && x in vals && obj[x] != (vals as Record<string, unknown>)[x]) {
        return false
      }
    }

    return true
  })
}

/**
 * Keeps a number within a range.
 */
export function clampRange(value: number, min = 0, max = 1) {
  return value < min ? min : value > max ? max : value
}

/**
 * Wraps a number around minimum and maximum value.
 */
export function wrap(value: number, length: number) {
  if (value < 0) {
    value = length + (value % length)
  }

  if (value >= length) {
    return value % length
  }

  return value
}

/**
 * Formats a value by adding thousands separators.
 */
const addThousandSeparators = (value: string, thousandSeparator: string) => {
  if (!thousandSeparator) {
    return value
  }

  return value.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
}

/**
 * Gets price from value in minor units and adds tax. Adds trailing zeros if needed.
 */
export const getPrice = (
  minorUnits: number,
  taxRate: number,
  hasTrailingZeros = false,
  thousandSeparator = ','
) => {
  const price = (minorUnits / 100) * (1 + taxRate)

  if (!hasTrailingZeros && price % 1 === 0) {
    return addThousandSeparators(`${price}`, thousandSeparator)
  }

  const parts = price.toFixed(2).split('.')
  parts[0] = addThousandSeparators(parts[0], thousandSeparator)

  return `${parts.join('.')}`
}

export const Keys = {
  ENTER: 13,
  SPACE: 32,
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
}

export const isBrowser = typeof window !== 'undefined'

/**
 * Window size hook that listens to resize event.
 */
export function useWindowSize() {
  function getSize() {
    return {
      width: isBrowser ? window.innerWidth : 0,
      height: isBrowser ? window.innerHeight : 0,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isBrowser) {
      return
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

/**
 * Parses optional page request parameter.
 */
export function parseOptionalParameter<T = string>(
  parameter?: T | T[] | null
): T | undefined {
  if (!parameter || (Array.isArray(parameter) && parameter.length === 0)) {
    return
  }

  return parseRequiredParameter<T>(parameter)
}

/**
 * Parses optional page request parameter array.
 */
export function parseOptionalParameters<T = string>(
  parameter?: T | T[] | null
): T[] | undefined {
  if (!parameter || (Array.isArray(parameter) && parameter.length === 0)) {
    return
  }

  return parseRequiredParameters<T>(parameter)
}

/**
 * Parses required page request parameter.
 */
export function parseRequiredParameter<T = string>(parameter: T | T[]): T {
  return Array.isArray(parameter) ? parameter[0] : parameter
}

/**
 * Parses required page request parameter array.
 */
export function parseRequiredParameters<T = string>(parameter: T | T[]): T[] {
  return Array.isArray(parameter) ? parameter : [parameter]
}

/**
 * Converts an enum into an object.
 */
export function enumToObject<T>(
  enumeration: T
): { [P in keyof T]: T[P] }[keyof T] {
  return enumeration as unknown as T[keyof T]
}

/**
 * Parses JSON string into an object.
 */
export const parseJson = (json: string): Record<string, unknown> => {
  try {
    return JSON.parse(json)
  } catch (_) {
    return {}
  }
}

/**
 * Compares numbers for sorting.
 */
export const compareNumbers = (number1: number, number2: number) =>
  number1 - number2

/**
 * Compares strings for sorting.
 */
export const compareStrings = (string1: string, string2: string) =>
  string1.localeCompare(string2)

/**
 * Filters duplicates from an array.
 */
export function filterDuplicates<T>(value: T, index: number, array: T[]) {
  return array.indexOf(value) === index
}

/**
 * Determines if 2 variables are equal using JSON representation.
 */
export const isEqual = (variable1: unknown, variable2: unknown) =>
  JSON.stringify(variable1) === JSON.stringify(variable2)

/**
 * Determines is user agent matches Apple Safari.
 */
export const isMobileSafari = () => {
  if (!isBrowser) {
    return false
  }

  return (
    !!navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
    !!navigator.userAgent.match(/AppleWebKit/)
  )
}

/**
 * Generates all combinations from multiple arrays.
 * E.g., getAllCombinations(['a', 'b'], ['1', '2']) returns [['a', '1'], ['a', '2'], ['b', '1'], ['b', '2']].
 */
export const getAllCombinations = (...arrays: string[][]): string[][] => {
  const initialValue: string[][] = [[]]

  return [...arrays].reduce(
    (resultArrays, array) =>
      resultArrays
        .map((resultArray) =>
          array.map((arrayValue) => resultArray.concat(arrayValue))
        )
        .reduce(
          (newResultArrays, arraysItem) => newResultArrays.concat(arraysItem),
          []
        ),
    initialValue
  )
}

/**
 * Gets and updates multiple URL parameters.
 */
export const useUrlParameters = (
  initialParameters: UrlParameter[]
): [UrlParameter[], (parameters: UrlParameter[]) => void] => {
  const router = useRouter()

  let currentParameters = initialParameters

  // If query parameters are present, update current parameters
  if (Object.keys(router.query).length > 0) {
    currentParameters = initialParameters.map((parameter) => {
      if (!router.query[parameter.name]) {
        return parameter
      }

      const newParameter: UrlParameter = {
        ...parameter,
        value: parseRequiredParameter(router.query[parameter.name]) ?? '',
      }

      return newParameter
    })
  }

  // Update query parameters on change
  const setCurrentParameters = useCallback(
    (parameters: UrlParameter[]) => {
      const slugs = parseOptionalParameters(router.query?.slug) ?? []
      const currentPath = ([] as string[]).concat(slugs).join('/')

      // Remove parameters that match initial parameters
      const filteredParameters = parameters.filter(
        ({ name, value }) =>
          value !==
          initialParameters.find(
            (initialParameter) => initialParameter.name === name
          )?.value
      )

      const urlParameters = filteredParameters.reduce<
        Record<string, string[] | undefined>
      >((result, { name, value }) => {
        result[name] = value && Array.isArray(value) ? value : value?.split(',')

        return result
      }, {})
      const urlQueryString = queryString.stringify(urlParameters, {
        arrayFormat: 'comma',
      })

      // Replace current URL with new parameters
      router.replace(
        `${currentPath}${urlQueryString ? `?${urlQueryString}` : ''}`,
        undefined,
        { shallow: true, scroll: false }
      )
    },
    [initialParameters, router]
  )

  return [currentParameters, setCurrentParameters]
}

/**
 * Gets formatted date by date string and locale.
 */
export const getFormattedDate = (
  date: string,
  locale: Locale,
  formatOptions?: Intl.DateTimeFormatOptions
) => {
  const dateTimeFormat = new Intl.DateTimeFormat(
    locale,
    formatOptions ?? {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
  )

  return dateTimeFormat.format(new Date(date))
}

/**
 * Gets the first existing value from an array of values.
 */
export function getFirstValue<T = string>(
  values: (T | undefined)[]
): T | undefined {
  return values.filter(Boolean)[0]
}

/**
 * Generates a random string based on UUID.
 */
export const getRandomString = () => Buffer.from(uuidv4()).toString('base64')
